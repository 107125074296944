
import { client } from '@/client';
import { defineComponent } from 'vue';
import { ElMessage } from 'element-plus';
export default defineComponent ({
  name: "admin-add",
  data: () => {
    return {
      params: {
        username: "",
        password: "",
        createdAt: new Date(),
        updatedAt: new Date(),
        status: "1",
      },
      paramsRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "2到20个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "6到20个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {},
  methods: {
    //新增
    async create() {
      try {
        let res = await client.callApi("admin/Create",{
          username: this.params.username,
          password: this.params.password,
        });
        if (res.isSucc) {
          ElMessage({
            message: "更新成功^_^",
            type: "success",
          });
          this.$router.go(-1);
        }
      } catch (error) {
        console.log(error);
      }
    },

    submit(formName: string) {
      let refs:any = this.$refs[formName];
      if (refs) {
        refs.validate((valid: any) => {
          if (valid) {
            this.create();
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }
    },
  },
});
